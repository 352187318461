import { axios } from '@/utils/request'
const basic = '/manage/question'
const api = {
  findTask: basic + '/findTask', // 分页查询任务
  findAllTask: basic + '/findAllTask', // 查询所有任务
  addQuestion: basic + '/addQuestion', // 添加问卷任务
  delTask: basic + '/delTask', // 删除任务
  detail: basic + '/detail', // 任务详情
  updateTaskStatus: basic + '/updateTaskStatus', // 修改任务状态
  uploadNumber: basic + '/uploadNumber' // 上传问卷号码
}

export function findTask (params) {
  return axios({
    url: api.findTask,
    method: 'post',
    params
  })
}
export function findAllTask () {
  return axios({
    url: api.findAllTask,
    method: 'post'
  })
}
export function addQuestion (data) {
  return axios({
    url: api.addQuestion,
    method: 'post',
    data
  })
}
export function detailTask (params) {
  return axios({
    url: api.detail,
    method: 'post',
    params
  })
}
export function delTask (params) {
  return axios({
    url: api.delTask,
    method: 'post',
    params
  })
}
export function uploadNumber (params) {
  return axios({
    url: api.uploadNumber,
    method: 'post',
    params: { id: params.id },
    data: params.file,
    timeout: 0
  })
}
export function updateTaskStatus(parameter) {
  return axios({
    url: api.updateTaskStatus,
    method: 'post',
    params: parameter
  })
}
