<template>
  <a-card :bordered="false">
    <p class="title">{{ title }}号码</p>
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="48">
          <a-col :md="8" :sm="24">
            <a-form-item label="手机号码">
              <a-input v-model="queryParam.phones"/>
            </a-form-item>
          </a-col>
          <a-col :md="8" :sm="24">
            <a-form-item label="号码状态">
              <a-select v-model="queryParam.callStatus">
                <a-select-option value="">全部</a-select-option>
                <a-select-option value="已接通">已接通</a-select-option>
                <a-select-option value="未接听">未接听</a-select-option>
<!--                <a-select-option value="坐席无人接听">坐席无人接听</a-select-option>-->
                <a-select-option value="其他">其他</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="2" :sm="24">
            <span class="table-page-search-submitButtons">
              <a-button type="primary" icon="search" @click="queryClick">查询</a-button>
            </span>
          </a-col>
          <a-col :md="2" :sm="24">
            <span class="table-page-search-submitButtons">
              <a-button type="primary" icon="reload" @click="reload">清空</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <a-table
      :columns="columns"
      :dataSource="data"
      rowKey="id"
      :loading="loading"
      :pagination="pagination"
      @change="pageChange">
      <!--      <span slot="time" slot-scope="time">-->
      <!--        {{ time | dateFormat }}-->
      <!--      </span>-->
    </a-table>
    <div class="backBtn">
      <a-button type="primary" @click="back">返回</a-button>
    </div>
  </a-card>
</template>

<script>
import { findCallRecord } from '@/api/questionnaire/record'
export default {
  name: 'PhoneList',
  data () {
    return {
      columns: [
        // {
        //   title: 'ID',
        //   dataIndex: 'id'
        // },
        // {
        //   title: '姓名',
        //   dataIndex: 'name'
        // },
        {
          title: '手机号码',
          dataIndex: 'phone'
        },
        {
          title: '号码状态',
          dataIndex: 'callStatus'
        },
        {
          title: '录入时间',
          dataIndex: 'entryTime'
        },
        {
          title: '呼出时间',
          dataIndex: 'answerTime'
        }
      ],
      pagination: {
        current: 1,
        total: 0,
        showTotal: function (total, range) {
          return '数据总数：' + total + ''
        },
        showQuickJumper: true,
        showSizeChanger: true,
        pageSizeOptions: [ '10', '30', '50' ]
      },
      data: [],
      queryParam: {
        phones: null,
        callStatus: null,
        call: null,
        page: 0,
        size: 10
      },
      loading: false
    }
  },
  props: {
    id: {
      type: Number,
      default: null
    },
    title: {
      type: String,
      default: ''
    }
  },
  filters: {
    dateFormat (date) {
      if (date) {
        let format = 'yyyy-MM-dd HH:mm:ss'
        date = new Date(date)
        date.setHours(date.getHours() - 14)
        const o = {
          'M+': date.getMonth() + 1, // month
          'd+': date.getDate(), // day
          'H+': date.getHours(), // hour
          'm+': date.getMinutes(), // minute
          's+': date.getSeconds(), // second
          'q+': Math.floor((date.getMonth() + 3) / 3), // quarter
          'S': date.getMilliseconds() // millisecond
        }
        if (/(y+)/.test(format)) {
          format = format.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
        }
        for (const k in o) {
          if (new RegExp('(' + k + ')').test(format)) { format = format.replace(RegExp.$1, RegExp.$1.length === 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length)) }
        }
        return format
      }
    }
  },
  mounted () {
    this.query()
  },
  methods: {
    query () {
      this.loading = true
      const data = { ...this.queryParam }
      if (data.phones) {
        data.phones = data.phones.split(',')
      }
      findCallRecord(data).then(res => {
        if (res.status !== 200) {
          throw new Error(res.message)
        }
        this.data = res.body.body
        this.pagination.total = res.body.total
      }).catch((e) => {
        this.$notification.error({
          message: '提示',
          description: e.message
        })
      }).finally(() => {
        this.loading = false
      })
    },
    queryClick () {
      this.pagination.current = 1
      this.queryParam.page = 0
      this.query()
    },
    pageChange (pageNumber) {
      this.pagination.current = pageNumber.current
      this.queryParam.page = pageNumber.current - 1
      this.queryParam.size = pageNumber.pageSize
      this.query()
    },
    reload () {
      this.queryParam = this.$options.data().queryParam
      this.queryParam.taskId = this.id
    },
    back () {
      this.$emit('cut', 'ListTaskComponent')
    }
  }
}
</script>

<style scoped>
  .title{
    text-align: center;
    font-size: 30px;
  }
.backBtn{
  margin: 30px auto;
  text-align: center;
}
</style>
