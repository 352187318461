import { axios } from '@/utils/request'
const basic = '/manage/call'
const api = {
  // 分页查询通话记录
  findQuestionCallRecord: basic + '/findQuestionCallRecord',
  // 导出excel
  exportQuestionExcel: basic + '/exportQuestionExcel',
  // 导出zip
  exportQuestionZip: basic + '/exportQuestionZip',
  questionDetail: 'manage/market/questionDetail', // 问卷记录查看详情
  updateCallQuestion: '/manage/market/updateCallQuestion', // 问卷记录修改,通话后修改模版状态
  updateQuestion: '/manage/market/updateQuestion'
}
export function findCallRecord (data) {
  return axios({
    url: api.findQuestionCallRecord,
    method: 'post',
    data: data,
    timeout: 0
  })
}
export function exportExcel (data) {
  return axios({
    url: api.exportQuestionExcel,
    method: 'post',
    responseType: 'blob',
    data: data,
    timeout: 0
  })
}
export function exportZip (data) {
  return axios({
    url: api.exportQuestionZip,
    method: 'post',
    responseType: 'blob',
    data: data,
    timeout: 0
  })
}
export function questionDetail (params) {
  return axios({
    url: api.questionDetail,
    method: 'post',
    params
  })
}
export function updateCallQuestion (data) {
  return axios({
    url: api.updateCallQuestion,
    method: 'post',
    data
  })
}
export function updateQuestion (data) {
  return axios({
    url: api.updateQuestion,
    method: 'post',
    data
  })
}
