<template>
  <a-card :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="48">
          <!--          <a-col :md="8" :sm="24">-->
          <!--            <a-form-item label="项目名称">-->
          <!--              <a-select-->
          <!--                v-model="queryParam.projectId"-->
          <!--                @change="projectChange"-->
          <!--                show-search-->
          <!--                :filter-option="filterOption">-->
          <!--                <a-select-option-->
          <!--                  v-for="(data,key) of allProject"-->
          <!--                  :key="key"-->
          <!--                  :value="data.id"-->
          <!--                >{{ data.name }}</a-select-option>-->
          <!--              </a-select>-->
          <!--            </a-form-item>-->
          <!--          </a-col>-->
          <a-col :md="6" :sm="24">
            <a-form-item label="任务名称">
              <!--              <a-input :maxLength="300" v-model="queryParam.taskName"/>-->
              <a-select v-model="queryParam.taskName" @change="fileNameChange" show-search :filter-option="filterOption">
                <a-select-option value="">全部</a-select-option>
                <a-select-option v-for="(item, key) of allTask" :key="key" :value="item.id">{{ item.name }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-item label="外呼状态">
              <a-select v-model="queryParam.status">
                <a-select-option value="CREATE">待呼叫</a-select-option>
                <a-select-option value="SUSPEND">已暂停</a-select-option>
                <a-select-option value="START">呼叫中</a-select-option>
                <a-select-option value="END">已完成</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-item label="创建时间">
              <a-range-picker @change="onChange" v-model="date" />
            </a-form-item>
          </a-col>
          <a-col :md="3" :sm="24">
            <span class="table-page-search-submitButtons">
              <a-button type="primary" icon="search" @click="queryClick">查询</a-button>
            </span>
          </a-col>
          <a-col :md="3" :sm="24">
            <span class="table-page-search-submitButtons">
              <a-button type="primary" icon="reload" @click="clearClick">清空</a-button>
            </span>
          </a-col>
        </a-row>
        <a-row :gutter="48"> </a-row>
        <a-row>
          <a-col :md="24" :sm="24">
            <span style="float: left;margin-bottom: 30px">
              <a-button type="primary" icon="plus" @click="addUserClick" v-if="$checkPermission('Action:QuestionTask:Add')">新增</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <a-table :columns="columns" :dataSource="data" rowKey="id" :loading="loading" :pagination="pagination" @change="pageChange">
      <span slot="answerRate" slot-scope="answerRate">
        {{ answerRate ? (answerRate * 100).toFixed(2) : 0 }}
      </span>
      <span slot="lossRate" slot-scope="lossRate">
        {{ lossRate ? (lossRate * 100).toFixed(2) : 0 }}
      </span>
      <span slot="status" slot-scope="status">
        {{ status | status }}
      </span>
      <span slot="action" slot-scope="text">
        <a-space>
          <a-button type="primary" size="small" @click="updUserClick(text, '查看')" v-if="$checkPermission('Action:QuestionTask:Find')">查看</a-button>
          <a-button type="primary" size="small" @click="updUserClick(text, '编辑')" v-if="$checkPermission('Action:QuestionTask:Edit')">编辑</a-button>
          <a-upload name="file" :multiple="true" :customRequest="upload" :showUploadList="false" v-if="$checkPermission('Action:QuestionTask:Import')">
            <a-button size="small" type="primary" @click.native="getId(text)">导入号码</a-button>
          </a-upload>
          <a-button type="primary" size="small" v-if="$checkPermission('Action:QuestionTask:Start') && text.statusOutbound !== 'START' && text.status === '已导入'" @click="start(text, 'START')">
            启动
          </a-button>
          <a-button type="primary" size="small" v-if="$checkPermission('Action:QuestionTask:Stop') && text.statusOutbound === 'START'" @click="start(text, 'SUSPEND')">暂停</a-button>
          <!--        <a-button type="primary" size="small" v-if="text.statusOutbound!=='END'" @click="start(text,'END')">结束</a-button>-->
          <!--        <a-divider type="vertical" v-if="text.statusOutbound!=='END'"/>-->
          <!--        <a-button type="primary" size="small" v-if="text.statusOutbound==='END'" @click="start(text,'END')">创建</a-button>-->
          <!--        <a-divider type="vertical" v-if="text.statusOutbound==='END'"/>-->
          <a-button type="primary" size="small" v-if="text.status === '已导入'" @click="phoneList(text)">号码列表</a-button>
          <a-popconfirm title="确认删除？" okText="是" cancelText="否" @confirm="delOk(text.id)" v-if="$checkPermission('Action:QuestionTask:Delete')">
            <a-button type="danger" size="small">删除</a-button>
          </a-popconfirm>
        </a-space>
      </span>
    </a-table>
  </a-card>
</template>

<script>
import moment from 'moment'
import { findAllProject } from '@/api/findAll'
import { findTask, findAllTask, delTask, uploadNumber, updateTaskStatus } from '@/api/questionnaire/task'

export default {
  name: 'ListTaskComponent',
  data() {
    return {
      showEditModal: false,
      editData: {},
      pagination: {
        current: 1,
        total: 0,
        showTotal: function(total, range) {
          return '数据总数：' + total + ''
        },
        showQuickJumper: true,
        showSizeChanger: true,
        pageSizeOptions: ['10', '30', '50']
      },
      loading: false,
      // 查询参数
      queryParam: {
        page: 0,
        size: 10,
        projectId: null,
        callStatus: null,
        taskName: null,
        startTime: null,
        endTime: null
      },
      data: [],
      date: [],
      columns: [
        {
          title: 'ID',
          dataIndex: 'id'
        },
        {
          title: '任务名称',
          dataIndex: 'name'
        },
        {
          title: '人员分组',
          dataIndex: 'organizationName'
        },
        {
          title: '号码总数',
          dataIndex: 'totalNumber'
        },
        {
          title: '有效号码数',
          dataIndex: 'validNumber'
        },
        {
          title: '外呼数量',
          dataIndex: 'outboundNumber'
        },
        {
          title: '剩余数',
          dataIndex: 'remainingNumber'
        },
        {
          title: '接通数',
          dataIndex: 'connectionNumber',
          scopedSlots: { customRender: 'connectionNumber' }
        },
        {
          title: '接通率(%)',
          dataIndex: 'answerRate',
          scopedSlots: { customRender: 'answerRate' }
        },
        {
          title: '呼损量',
          dataIndex: 'lossNumber'
        },
        {
          title: '呼损率(%)',
          dataIndex: 'lossRate',
          scopedSlots: { customRender: 'lossRate' }
        },
        {
          title: '号码状态',
          dataIndex: 'status'
        },
        {
          title: '外呼状态',
          dataIndex: 'statusOutbound',
          scopedSlots: { customRender: 'status' }
        },
        {
          title: '创建时间',
          dataIndex: 'createTime'
        },
        {
          title: '操作',
          key: 'operation',
          fixed: 'right',
          scopedSlots: { customRender: 'action' }
        }
      ],
      allProject: [],
      allTask: [],
      taskId: null
    }
  },
  filters: {
    status(value) {
      if (value === 'CREATE') {
        return '待呼叫'
      } else if (value === 'SUSPEND') {
        return '已暂停'
      } else if (value === 'START') {
        return '呼叫中'
      } else if (value === 'END') {
        return '已完成'
      }
    }
  },
  created() {
    this.query()
    this.findAllProject()
    this.projectChange()
  },
  activated() {
    this.query()
  },
  methods: {
    moment,
    delOk(id) {
      delTask({ id: id }).then(res => {
        if (res.status !== 200) {
          this.$notification.error({
            message: '提示',
            description: res.message
          })
          return
        }
        this.$notification.success({
          message: '提示',
          description: res.message
        })
        if (this.data.length === 1 && this.pagination.total > 1) {
          this.queryParam.page -= 1
          this.pagination.current -= 1
        } else if (this.data.length === 1 && this.pagination.total === 1) {
          this.queryParam.page = 0
          this.pagination.current = 1
        }
        this.query()
      })
    },
    findAllProject() {
      findAllProject()
        .then(res => {
          if (res.status !== 200) {
            throw new Error(res.message)
          }
          this.allProject = res.body
        })
        .catch(e => {
          this.$notification.error({
            message: '提示',
            description: e.message
          })
        })
    },
    projectChange() {
      findAllTask()
        .then(res => {
          console.log(res)
          if (res.status !== 200) {
            throw new Error(res.message)
          }
          this.allTask = res.body
          this.queryParam.taskName = null
        })
        .catch(e => {
          this.$notification.error({
            message: '提示',
            description: e.message
          })
        })
    },
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    fileNameChange(value) {
      const index = this.allTask.findIndex(data => {
        return value === data.id
      })
      if (index === -1) {
        this.fileName = '全部任务'
      } else {
        this.fileName = this.allTask[index].name
        this.queryParam.taskName = this.allTask[index].name
      }
    },
    onChange(date, dateString) {
      this.queryParam.startTime = dateString[0]
      this.queryParam.endTime = dateString[1]
    },
    cancelHandle() {
      this.showEditModal = false
      this.editData = {}
    },
    editHandle() {
      this.cancelHandle()
      this.query()
    },
    updUserClick(row, type) {
      this.$emit('cut', 'EditTaskComponent', type, row.id)
      // this.editData = { ...JSON.parse(JSON.stringify(row)), ...{ type } }
      // this.showEditModal = true
    },
    addUserClick() {
      // this.showEditModal = true
      this.$emit('cut', 'EditTaskComponent', '新增')
    },
    pageChange(pageNumber) {
      this.pagination.current = pageNumber.current
      this.queryParam.page = pageNumber.current - 1
      this.queryParam.size = pageNumber.pageSize
      this.query()
    },
    queryClick() {
      this.pagination.current = 1
      this.queryParam.page = 0
      this.query()
    },
    clearClick() {
      this.queryParam = this.$options.data().queryParam
      this.date = []
    },
    phoneList(value) {
      this.$emit('cut', 'PhoneList', '查看', value.id)
    },
    upload(row) {
      const param = new FormData()
      console.log(row.file)
      param.append('file', row.file)
      const data = {
        file: param,
        id: this.taskId
      }
      uploadNumber(data)
        .then(res => {
          if (res.status !== 200) {
            this.$notification.error({
              message: '提示',
              description: res.message
            })
            return
          }
          this.$notification.success({
            message: '提示',
            description: res.message
          })
          this.query()
        })
        .catch(e => {
          this.$notification.error({
            message: '提示',
            description: e.message
          })
        })
        .finally(() => {
          this.confirmLoading = false
        })
    },
    getId(text) {
      this.taskId = text.id
    },
    query() {
      this.loading = true
      findTask(this.queryParam)
        .then(res => {
          if (res.status !== 200) {
            throw new Error(res.message)
          }
          this.data = res.body.body
          this.pagination.total = res.body.total
        })
        .catch(e => {
          this.$notification.error({
            message: '提示',
            description: e.message
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
    start(text, status) {
      updateTaskStatus({ taskId: text.id, status }).then(res => {
        if (res.status !== 200) {
          this.$notification.error({
            message: '提示',
            description: res.message
          })
          return
        }
        this.$notification.success({
          message: '提示',
          description: res.message
        })
        this.query()
      })
    }
  }
}
</script>

<style scoped></style>
